import { useAtom } from 'jotai';
import React from 'react';
import { paginationAtom } from './globalAtoms';

type PagesChange = (currentPage: number) => void;
type PagesCurrent = number;
type Pages = {
    change: PagesChange,
    current: PagesCurrent
};

type TotalChange = (currentTotal: number) => void;
type TotalCurrent = number;
type Totals = {
    change: TotalChange;
    current: TotalCurrent;
}

type ItemsPerChange = (itemsPer: number) => void;
type ItemsPerCurrent = number;
type ItemsPerOptions = number[];
type ItemsPer = {
    change: ItemsPerChange,
    current: ItemsPerCurrent,
    options: ItemsPerOptions
}

export type PaginationInfo = {
    pages: Pages;
    totals: Totals;
    itemsPerPage: ItemsPer;
}

type UsePaginationType = [
    PaginationInfo
];

interface PaginationAtomType {
    component: string; 
    currentPage: number; 
    totalItems: number;
    selectedItemsPerPage: number;
}

export const usePagination = (component: string, itemsPerPage: number[] = [10], enableAtom = true): UsePaginationType => {
    const [pagination, setPagination] = useAtom(paginationAtom);
    const [itemsPer] = React.useState<number[]>(itemsPerPage);

    const [selectedItemsPerPage, _setSelectedItemsPerPage] = React.useState(itemsPer[0]);
    const [currentPage, _setCurrentPage] = React.useState(0);
    const [totalItems, _setTotalItems] = React.useState(0);

    React.useEffect(() => {
        const atomHasComponent = pagination.find((value: PaginationAtomType) => value.component === component);
        if (!atomHasComponent && enableAtom) {
            const updateTo = [
                ...pagination,
                {
                    component: component,
                    currentPage: 0,
                    totalItems: 0,
                    selectedItemsPerPage: itemsPerPage[0]
                }
            ];
            setPagination(updateTo)
        } else if (enableAtom) {
            _setSelectedItemsPerPage(atomHasComponent.selectedItemsPerPage);
            _setCurrentPage(atomHasComponent.currentPage);
            _setTotalItems(atomHasComponent.totalItems);
        }
    }, [component]);

    const setSelectedItemsPerPage = (selectedItemsPerPage: number): void => {
        _setSelectedItemsPerPage(selectedItemsPerPage);
        if ((pagination.length > 0 && totalItems !== 0) && enableAtom) {
            setPagination([...pagination.map((value: PaginationAtomType) => {
                if (value.component === component) {
                    value.currentPage = 0;
                    value.selectedItemsPerPage = selectedItemsPerPage;
                }
                return value;
            })]);
        }
    }

    const setCurrentPage = (currentPage: number) => {
        _setCurrentPage(currentPage);
        if ((pagination.length > 0 && totalItems !== 0) && enableAtom) {
            setPagination([...pagination.map((value: PaginationAtomType) => {
                if (value.component === component) {
                    value.currentPage = currentPage;
                }
                return value;
            })]);
        }
    }

    const setTotalItems = (totalItems: number) => {
        _setTotalItems(totalItems);
        if (enableAtom) {
            setPagination([...pagination.map((value: PaginationAtomType) => {
                if (value.component === component) {
                    value.totalItems = totalItems;
                }
                return value;
            })]);
        }
    }

    const atomHasComponent: PaginationAtomType | undefined = pagination.find((value: PaginationAtomType) => value.component === component);

    return [
        {
            pages: {
                change: setCurrentPage,
                current: atomHasComponent ? atomHasComponent.currentPage : currentPage
            },
            totals: {
                change: setTotalItems,
                current: atomHasComponent ? atomHasComponent.totalItems : totalItems
            },
            itemsPerPage: {
                change: setSelectedItemsPerPage,
                current: atomHasComponent ? atomHasComponent.selectedItemsPerPage : selectedItemsPerPage,
                options: itemsPer
            }
        }
    ];
}